import { UseInfiniteHitsProps, UseNumericMenuProps, UseRefinementListProps } from 'react-instantsearch'
import { Thumbnails } from '../types'

type Image = {
  url: string
  dimensions?: { w: number; h: number }
  label?: string
}

type SingleOrRangePrice = {
  centAmount: number[]
  currency: string
}

export interface Thumbnail {
  id: string
  color_name: string
  swatch_url: string
  image_url: string
}

export interface Department {
  node_key: string
  name: string
}

export type Price = {
  centAmount: number
  currency: string
}

//This exists to satisfy Algolia's generics expectations
type BaseHit = Record<string, unknown>

export interface AlgoliaProduct extends BaseHit {
  objectID: string
  key: string
  categories: string[]
  department: Department
  name: string
  slug: string
  brand: string
  description: string
  facetColors: string[]
  image?: Image
  thumbnails?: Thumbnail[]
  isPublished: boolean
  price?: SingleOrRangePrice

  // Algolia Price Data
  basePrice?: Price
  edsPrice?: Price
  discountAmount?: number
  saleEvent?: string
  discounted?: Price
  isClearance?: boolean
  variantPrice?: any

  features: string[]
  performanceFeatures: string[]
  sleeveLength: string
  PantFit: string
  isDefaultVariation: boolean
  combinedCollarAndShirtTypes: string[]
  inStock: boolean
  graphic: string[]
  hatTypes: string
  jacketStyle: string[]
  JacketWeight: string
  league: string
  material: string[]
  occasion: string
  PackageQty: string
  shortsInseam: string[]
  sockHeight: string
  style: string[]
  accessoryTypes: string[]
  pantShortTypes: string[]
  printsPatterns: string[]
  Types: string[]
  upcCodes: string[]
  team: string[]
  isExclusiveSizes?: boolean
  isOnlineOnly?: boolean
  availability?: {
    availabilestore?: string[]
    availabilitystatus?: string
    backorderlevel?: number
    updatedate?: string
    availabilitydate?: string
  }
  [key: string]: any
}

export type Locale = {
  languageCode: 'en' | 'fr'
  countryLanguageCode: 'en-US' | 'en-CA' | 'fr-CA'
  currency: 'USD' | 'CAD'
}

/**
 * This type MUST correspond to the postfix name of an index in Algolia, ex [BASE_INDEX_NAME]_[SortByOption]
 */
export type SortByOption = 'newest' | 'priceAsc' | 'priceDesc' | 'nameAsc'

export type AlgoliaFilterItem = (
  | (NumericMenuProps & Numeric)
  | (RefinementListProps & List)
  | (RefinementListProps & Range)
) &
  UrlAlias

export interface NumericMenuProps extends UseNumericMenuProps, RefinementBaseProps {}
export interface RefinementListProps extends UseRefinementListProps, RefinementBaseProps {}

export interface RefinementBaseProps {
  /** Translation key for react-intl. Used to label the Refinement */
  translationKey: string
  storePickup?: boolean
  infiniteHits?: UseInfiniteHitsProps<StarterKitAlgoliaProduct>
}

//buliding block types for the AlgoliaFilterItem interface
type Numeric = { type: 'numeric' }
type List = { type: 'list' }
type Range = { type: 'range' }

/** This alias will be used to map the filter to the url. Must be unique. */
type UrlAlias = {
  urlAlias: string
}

export type StarterKitAlgoliaProduct = AlgoliaProduct & {
  // Custom typings go here
  thumbnails: Thumbnails[]
  brand: string
  handleProductsData?: (hit: StarterKitAlgoliaProduct) => void
}

export enum PLPPriceLayouts {
  STANDARD,
  DISCOUNT_EVENT,
  EDS_PROMO,
}

export interface ProductListingPageProps {
  query: string
  isSearchPage?: boolean
}
